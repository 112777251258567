// Constants for the API base URL and blockchain explorers
const production = process.env.VUE_APP_PRODUCTION === "1";

const API_BASE_URL = production
    ? process.env.VUE_APP_PRODUCTION_API
    : process.env.VUE_APP_DEV_API;

const TRADING_BASE_URL = production
    ? process.env.VUE_APP_TRADING_PRODUCTION
    : process.env.VUE_APP_TRADING_DEV;

const WEBSOCKET_BASE_URL = production
    ? process.env.VUE_APP_WEBSOCKET_PRODUCTION
    : process.env.VUE_APP_WEBSOCKET_DEV;

const WEBSOCKET_PORT = production
    ? process.env.VUE_APP_WEBSOCKET_PORT_PRODUCTION
    : process.env.VUE_APP_WEBSOCKET_PORT_DEV;

const WEBSOCKET_PROTOCOL = production
    ? 'wss'
    : 'ws';

const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY;

const EXPLORER = {
  BTC: 'https://www.blockchain.com/explorer/transactions/btc/',
  ETH: 'https://etherscan.io/tx/',
  TRON: 'https://tronscan.org/#/transaction/',
  MATIC: 'https://polygonscan.com/tx/',
  ONE: 'https://explorer.harmony.one/tx/',
  ADA: 'https://cardanoscan.io/transaction/',
  LTC: 'https://blockchair.com/litecoin/transaction/',
  KLAY: 'https://scope.klaytn.com/tx/',
  CELO: 'https://explorer.celo.org/mainnet/tx/',
  XRP: 'https://xrpscan.com/tx/',
  BCH: 'https://blockchair.com/bitcoin-cash/transaction/',
  ZEC: 'https://blockchair.com/zcash/transaction/',
  DASH: 'https://blockchair.com/dash/transaction/',
  XLM: 'https://stellarchain.io/transactions/',
  ATOM: 'https://www.mintscan.io/cosmos/tx/',
  ARB: 'https://arbiscan.io/tx/',
  AVAX: 'https://snowtrace.io/tx/',
};

// Function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Check if UUID is stored in local storage, generate and store if not
const UUID = localStorage.getItem('UUID') || generateUUID();
localStorage.setItem('UUID', UUID);

// Function to get user device information
function getUserDeviceInfo() {
  const userAgent = navigator.userAgent.toLowerCase();
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const mobileKeywords = ['android', 'iphone', 'windows phone'];
  const tabletKeywords = ['ipad', 'android'];

  const hasKeyword = (keywords) => keywords.some(keyword => userAgent.includes(keyword));

  if (hasKeyword(mobileKeywords) || (screenWidth < 768 && !hasKeyword(tabletKeywords))) {
    return {
      type: 'Mobile',
      details: `Width: ${screenWidth}, Height: ${screenHeight}`,
    };
  } else if (hasKeyword(tabletKeywords) || (screenWidth >= 768 && screenWidth < 1024)) {
    return {
      type: 'Tablet',
      details: `Width: ${screenWidth}, Height: ${screenHeight}`
    };
  } else {
    return {
      type: 'Desktop',
      details: `Width: ${screenWidth}, Height: ${screenHeight}`
    };
  }
}

// Get user device information from local storage or generate and store it
let DEVICE = JSON.parse(localStorage.getItem('DEVICE'));
if (!DEVICE) {
  DEVICE = getUserDeviceInfo();
  localStorage.setItem('DEVICE', JSON.stringify(DEVICE));
}

// Export the constants and device information as a module
export default {
  API_BASE_URL,
  TRADING_BASE_URL,
  WEBSOCKET_BASE_URL,
  WEBSOCKET_PORT,
  WEBSOCKET_PROTOCOL,
  PUSHER_KEY,
  EXPLORER,
  UUID,
  DEVICE,
};