import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n";
import { defineAsyncComponent } from "vue";
import 'bootstrap/dist/css/bootstrap.min.css';
import "tailwindcss/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { checkTokenValidity } from '@/utils';
import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { Network } from '@capacitor/network';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';

// Import language JSON files for i18n
import en from "./locales/en.json";
import es from "./locales/es.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";
import de from "./locales/de.json";
import nl from "./locales/nl.json";
import pt from "./locales/pt.json";
import ru from "./locales/ru.json";
import zhCN from "./locales/zh-cn.json";
import zhTW from "./locales/zh-tw.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import id from "./locales/id.json";
import hi from "./locales/hi.json";
import th from "./locales/th.json";

// Get the stored language or use English as a default
const savedLocale = localStorage.getItem("language") || 'en';

// Create a Vue I18n instance
const i18n = createI18n({
    locale: savedLocale,
    fallbackLocale: 'en',
    messages: { en, es, fr, it, de, nl, pt, ru, zhCN, zhTW, ja, ko, id, hi, th }
});

// Define a function to lazily load components
const loadComponent = (component) => defineAsyncComponent(() => import(`./${component}.vue`));

// Define your Vue components
const App = loadComponent("App");

// Define routes for Vue Router
const routes = [
    { path: "/", component: () => import("./AppHome.vue") },
    { path: "/knowledge-base", component: () => import("./AppKnowledgeBase.vue") },
    { path: "/login", component: () => import("./AppLogin.vue") },
    { path: "/register", component: () => import("./AppRegister.vue") },
    { path: "/confirm-email", component: () => import("./AppConfirmEmail.vue") },
    { path: "/reset-password", component: () => import("./AppResetPassword.vue") },
    { path: "/dashboard", name: "dashboard", component: () => import("./AppDashboard.vue"), meta: { requiresAuth: true } },
    { path: "/admin", name: "admin", component: () => import("./AppAdmin.vue"), meta: { requiresAuth: true } },
    { path: "/wallets", name: "wallets", component: () => import("./AppDashboardWallets.vue"), meta: { requiresAuth: true } },
    { path: "/deposit", name: "deposit", component: () => import("./AppDashboardDeposit.vue"), meta: { requiresAuth: true } },
    { path: "/withdrawal", name: "withdrawal", component: () => import("./AppDashboardWithdrawal.vue"), meta: { requiresAuth: true } },
    { path: "/transfer", name: "transfer", component: () => import("./AppDashboardTransfer.vue"), meta: { requiresAuth: true } },
    { path: "/convert", name: "convert", component: () => import("./AppDashboardConvert.vue"), meta: { requiresAuth: true } },
    { path: "/account", name: "account", component: () => import("./AppDashboardAccount.vue"), meta: { requiresAuth: true } },
    { path: "/affiliate", name: "affiliate", component: () => import("./AppDashboardAffiliate.vue"), meta: { requiresAuth: true } },
    { path: "/trade", name: "trade", component: () => import("./AppTrade.vue"), meta: { requiresAuth: true } },
];

// Create Vue Router instance
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});

// Add a navigation guard to check for authentication
function isPWA() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
}

router.beforeEach( async (to, from, next) => {
    // MAINTENANCE
    if (process.env.VUE_APP_MAINTENANCE === "1") {
        next("/maintenance");
        return;
    }

    // AUTHENTICATION / TOKEN
    const UUID = localStorage.getItem("UUID");
    const authToken = localStorage.getItem("_Everest_" + UUID);
    const isAuthenticated = authToken !== null;
    let refreshingToken = false;

    if (isAuthenticated) {
        const now = new Date().getTime();
        const expirationTime = parseInt(localStorage.getItem("_MtBlanc_" + UUID), 10);

        if (now > expirationTime) {
            // Token is expired, call checkTokenValidity and continue afterward
            refreshingToken = true;
            await checkTokenValidity()
                .then(() => {
                    refreshingToken = false;
                })
                .catch((error) => {
                    // Handle errors from checkTokenValidity
                    console.error("Token validation error:", error);
                });
        }

        // Redirect logic for PWA root path and authenticated user
        if (isPWA() && to.path === "/") {
            next("/dashboard");
            return;
        }

        // Redirect logic for login/register paths and authenticated user
        if ((to.path === "/login" || to.path === "/register")) {
            next("/dashboard");
            return;
        }
        next();
    } else {
        if (process.env.VUE_APP_REGISTER === "0" && to.path === "/register") {
            alert("Registration is temporarily deactivated.");
            next("/");
            return;
        }

        if (process.env.VUE_APP_LOGIN === "0" && to.path === "/login") {
            alert("Login is temporarily deactivated.");
            next("/");
        }

        // Redirect logic for authenticated routes
        if (to.meta.requiresAuth) {
            next("/login");
            return;
        }
    }

    next();
});

// Capacitor / Mobile
// Check if the current platform is supported (not 'web')
if (Capacitor.isNativePlatform()) {
    // Request permission for using local notifications
    LocalNotifications.requestPermissions().then((result) => {
        if (result.granted) {
            // Permission was granted
            // Schedule a dummy local notification here, if desired
        }
    });

    // Register with the push notification service
    PushNotifications.requestPermissions().then((result) => {
        if (result.granted) {
            // Permission was granted
            PushNotifications.register();
        }
    });

    // Check network status
    Network.getStatus().then((status) => {
        console.log('Network status:', status);
    });

    // You can also listen for network status changes
    Network.addListener('networkStatusChange', (status) => {
        console.log("Network status changed:", status);
    });
}

// Create the Vue app and mount it to the DOM
const app = createApp(App);
if (Capacitor.isNativePlatform()) {
    app.config.globalProperties.$clipboard = Clipboard;
}
app.use(router);
app.use(i18n);
app.mount("#app");