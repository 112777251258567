import config from "@/config.js";
import axios from "axios";

export async function getAuthToken() {
    const UUID = localStorage.getItem("UUID");
    return localStorage.getItem("_Everest_" + UUID);
}

/**
 * ApiService - A service class for making API requests.
 */
export default class ApiService {
    constructor() {
        // Set up request interceptor to update 'Authorization' header
        axios.interceptors.request.use(async (config) => {
            axios.defaults.headers.common['Authorization'] = `X-AUTH-KEY ${process.env.VUE_APP_LARAVEL_APP_AUTH_KEY}`;

            return config;
        });
    }

    /**
     * Send a GET request to the specified API endpoint.
     * @param authToken
     * @param {string} endpoint - The endpoint to send the request to.
     * @param {Object} params - Optional query parameters.
     * @returns {Promise} A promise that resolves with the response data.
     */
    async get(authToken, endpoint, params = {}) {
        axios.defaults.headers.common['Authorization'] = `X-AUTH-KEY ${process.env.VUE_APP_LARAVEL_APP_AUTH_KEY}`;

        if(authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        }

        return axios.get(`${config.API_BASE_URL}/${endpoint}`, { params });
    }

    /**
     * Send a POST request to the specified API endpoint.
     * @param authToken
     * @param {string} endpoint - The endpoint to send the request to.
     * @param {Object} data - The data to include in the request body.
     * @returns {Promise} A promise that resolves with the response data.
     */
    async post(authToken, endpoint, data = {}) {
        axios.defaults.headers.common['Authorization'] = `X-AUTH-KEY ${process.env.VUE_APP_LARAVEL_APP_AUTH_KEY}`;

        if(authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        }

        return axios.post(`${config.API_BASE_URL}/${endpoint}`, data);
    }

    /**
     * Send a PUT request to the specified API endpoint.
     * @param authToken
     * @param {string} endpoint - The endpoint to send the request to.
     * @param {Object} data - The data to include in the request body.
     * @returns {Promise} A promise that resolves with the response data.
     */
    async put(authToken, endpoint, data = {}) {
        axios.defaults.headers.common['Authorization'] = `X-AUTH-KEY ${process.env.VUE_APP_LARAVEL_APP_AUTH_KEY}`;

        if(authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        }

        return axios.put(`${config.API_BASE_URL}/${endpoint}`, data);
    }

    /**
     * Send a DELETE request to the specified API endpoint.
     * @param authToken
     * @param {string} endpoint - The endpoint to send the request to.
     * @param {Object} params - Optional query parameters.
     * @returns {Promise} A promise that resolves with the response data.
     */
    async delete(authToken, endpoint, params = {}) {
        axios.defaults.headers.common['Authorization'] = `X-AUTH-KEY ${process.env.VUE_APP_LARAVEL_APP_AUTH_KEY}`;

        if(authToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        }

        return axios.delete(`${config.API_BASE_URL}/${endpoint}`, { params });
    }
}

/**
 * Handle error responses from API requests.
 * @param {Object} error - Error object from axios request.
 */
export async function handleErrorResponse(error) {
    if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
            await logout();
        } else {
            console.error("HTTP Error:", statusCode);
        }
    } else {
        console.error("Error:", error.message);
    }
}

async function logout() {
    const UUID = localStorage.getItem("UUID");
    const DENALI = localStorage.getItem("_Denali_");

    localStorage.removeItem("_Denali_");
    localStorage.removeItem("_Everest_" + UUID);
    localStorage.removeItem("_MtBlanc_" + UUID);
    localStorage.removeItem("launchWizard");

    alert("Your session has expired! Please log in again.");

    await axios.post(`${config.API_BASE_URL}/logout-no-token`, {UUID, DENALI});

    document.location.href = "/login";

    return false;
}

/**
 * Check the validity of the authentication token.
 */
export async function checkTokenValidity() {
    const authToken = await getAuthToken();

    if (!authToken) {
        await logout();
    }

    try {
        const checkToken = await fetchData('check-token');

        if(checkToken.status === 201) {
            await refreshToken();
        }
    } catch (error) {
        console.error("Token validation failed:", error);
        await handleErrorResponse(error);
    }
}

/**
 * Refresh the authentication token.
 */
export async function refreshToken() {
    const apiService = new ApiService();
    const UUID = localStorage.getItem("UUID");
    const DEVICE = localStorage.getItem("DEVICE");
    const expirationTime = new Date().getTime() + 10 * 60 * 1000;

    try {
        const response = await apiService.get(await getAuthToken(), 'refresh-token', {
            uuid: UUID,
            device: DEVICE
        });

        localStorage.setItem("_Everest_" + UUID, response.data.token);
        localStorage.setItem("_MtBlanc_" + UUID, expirationTime.toString());
    } catch (error) {
        await handleErrorResponse(error);
    }
}

/**
 * Utility functions for formatting values.
 */

/**
 * Format a value as currency.
 * @param {number} value - The value to format.
 * @param min
 * @param max
 * @param grouping
 * @returns {string|number} The formatted currency value.
 */
export function formatDigit(value, { min = 2, max = 8, grouping = true } = {}) {
    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
        useGrouping: grouping,
    }).format(value);
}

/**
 * Format a value with a specific number of decimal places.
 * @param {number} value - The value to format.
 * @param {number} decimals - The number of decimal places.
 * @returns {string} The formatted value.
 */
export function formatDigitWithDecimals(value, decimals) {
    return parseFloat(value).toFixed(decimals);
}

/**
 * Trim Trailing 0
 * @param {number} value - The value to format.
 * @returns {string} The formatted value.
 */
export function trimTrailingZeros(value) {
    // Convert the number to a string
    const stringValue = value.toString();

    // Use regular expression to remove trailing zeros
    return stringValue.replace(/\.?0*$/, '');
}

/**
 * Format a total value.
 * @param {number} value - The value to format.
 * @returns {string} The formatted total value.
 */
export function formatTotal(value) {
    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
}

/**
 * Format a date string.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
export function formatDateOnly(dateString) {
    return new Date(dateString).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

/**
 * Format a date string.
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */
export function formatDate(dateString) {
    return new Date(dateString).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
}

/**
 * Format a time from a date string.
 * @param {string} dateString - The date string to extract the time from.
 * @returns {string} The formatted time.
 */
export function formatTime(dateString) {
    const date = new Date(dateString);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
}

/**
 * Functions for fetching user-related data.
 */

/**
 * Fetch user data.
 * @returns {Promise} A promise that resolves with user data.
 */
export async function getUser() {
    return fetchData('user');
}

/**
 * Fetch user sessions data.
 * @returns {Promise} A promise that resolves with user sessions data.
 */
export async function getUserSessions() {
    return fetchData('get-tokens');
}

/**
 * Fetch trading assets data.
 * @returns {Promise} A promise that resolves with trading assets data.
 */
export async function getTradingAssets() {
    return fetchData('trading-assets');
}

/**
 * Fetch assets data.
 * @returns {Promise} A promise that resolves with assets data.
 */
export async function getAssets() {
    return fetchData('assets');
}

/**
 * Fetch asset data by currency.
 * @param {string} symbol - The currency to fetch data for.
 * @returns {Promise} A promise that resolves with asset data.
 */
export async function getAssetBySymbol(symbol) {
    return fetchData('asset-by-currency', { symbol });
}

/**
 * Fetch user wallets data.
 * @returns {Promise} A promise that resolves with user wallets data.
 */
export async function getUserWallets() {
    return fetchData('user-wallets');
}

/**
 * Fetch user wallet data for a specific currency.
 * @param {string} symbol - The currency to fetch wallet data for.
 * @returns {Promise} A promise that resolves with user wallet data.
 */
export async function getUserWallet(symbol) {
    return fetchData('user-wallet', { symbol });
}

/**
 * Fetch user wallets data for a specific currency.
 * @param {string} symbol - The currency to filter wallets by.
 * @returns {Promise} A promise that resolves with user wallets data.
 */
export async function getUserWalletsBySymbol(symbol) {
    return fetchData('user-wallets-symbol', { symbol });
}

/**
 * Fetch wallet transactions data.
 * @param {string} type - The type of wallet transactions to fetch.
 * @param {Array} walletIds - An array of wallet IDs to filter transactions by.
 * @returns {Promise} A promise that resolves with wallet transactions data.
 */
export async function getWalletsTransactions(type, walletIds) {
    return fetchData('user-wallet-transactions', { type, wallets: walletIds });
}

/**
 * Fetch all user fiat wallets data.
 * @returns {Promise} A promise that resolves with user wallet data.
 */
export async function getUserFiatWallets() {
    return fetchData('user-fiat-wallets');
}

/**
 * Fetch user fiat wallet data for a specific currency.
 * @param {string} currency - The currency to fetch wallet data for.
 * @returns {Promise} A promise that resolves with user wallet data.
 */
export async function getUserFiatWallet(currency) {
    return fetchData('user-fiat-wallet', { currency });
}

/**
 * Utility function to handle repeated fetch patterns.
 * @param {string} endpoint - API endpoint.
 * @param {Object} params - Additional parameters.
 * @returns {Promise} A promise that resolves with the fetched data.
 */
async function fetchData(endpoint, params = {}) {
    const apiService = new ApiService();
    const authToken = await getAuthToken();
    try {
        return await apiService.get(authToken, endpoint, params);
    } catch (error) {
        console.error(`Error fetching data from ${endpoint} endpoint:`, error);
        throw error;
    }
}
